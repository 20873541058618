import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-imagepicer',
  templateUrl: './imagepicer.component.html',
  styleUrls: ['./imagepicer.component.scss']
})
export class ImagepicerComponent {
  innerWidth = window.innerWidth;

  constructor(public dialog: MatDialog) { }

  imageClick(n) {
    this.dialog.open(DialogComponent, {
      width: '100%',
      maxWidth: '1024px',
      height: '80%',
      data: {
        img: n
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  type = null;
  src = null;
  text = null;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private sanitizer: DomSanitizer
  ) {
    if (data.img) {
      this.type = 1;
      this.src = this.sanitizer.bypassSecurityTrustStyle('url(assets/images/img' + data.img + '_compressed.jpg)');
    } else if(data.text) {
      this.type = 2;
      this.text = data.text;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'src/services/translate.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  list1 = [];
  list2 = [];

  constructor(private translate: TranslateService) {
    this.translate.onchange.subscribe(() => {
      this.list1 = translate.get('SERVICES_LIST_1');
      this.list2 = translate.get('SERVICES_LIST_2');
    });
  }

  ngOnInit() {
  }

}

import { Component } from '@angular/core';
import { DialogComponent } from './dialog/dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'kidzdoc';

  // constructor(private dialog: MatDialog) {
  //   this.dialog.open(DialogComponent, {
  //     disableClose: true,
  //     data: {
  //       text: 'Site is under construction'
  //     }
  //   });
  // }
}

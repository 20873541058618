import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'src/services/translate.service';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss']
})
export class LanguagePickerComponent implements OnInit {
  lang = 'en';

  constructor(public translate: TranslateService) { }

  ngOnInit() {
    this.lang = this.translate.lang;
  }

  setLang(lang: string) {
    this.translate.use(lang);
  }
}

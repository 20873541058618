import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'src/services/translate.service';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss']
})
export class OpeningHoursComponent implements OnInit {
  displayedColumns = [];
  dataSource = [];

  constructor(private translate: TranslateService) {
    this.translate.onchange.subscribe(() => {
      this.dataSource = translate.get('OPENING_HOURS_DATA');
      this.displayedColumns = translate.get('OPENING_HOURS_COLUMNS');
    });
  }

  ngOnInit() {
  }
}

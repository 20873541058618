import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { TranslateService } from 'src/services/translate.service';
import { TranslatePipe } from '../pipes/translate.pipe';
import { LanguagePickerComponent } from './language-picker/language-picker.component';
import { HomeComponent } from './home/home.component';
import { TopbarComponent } from './topbar/topbar.component';
import { MaterialModule } from 'src/modules/material.module';
import { ContactComponent } from './contact/contact.component';
import { BookingComponent } from './booking/booking.component';
import { DialogComponent } from './dialog/dialog.component';
import { WallpaperComponent } from './wallpaper/wallpaper.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { OpeningHoursComponent } from './opening-hours/opening-hours.component';
import { ImagepicerComponent } from './imagepicer/imagepicer.component';
import { ServicesComponent } from './services/services.component';
import { FooterComponent } from './footer/footer.component';


export function setupTranslateFactory(service: TranslateService) {
  return () => service.selectBrowserLang();
}

@NgModule({
  declarations: [
    AppComponent,
    TranslatePipe,
    LanguagePickerComponent,
    HomeComponent,
    TopbarComponent,
    ContactComponent,
    BookingComponent,
    DialogComponent,
    WallpaperComponent,
    AboutUsComponent,
    OpeningHoursComponent,
    ImagepicerComponent,
    ServicesComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MaterialModule
  ],
  providers: [
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [ TranslateService ],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [DialogComponent]
})
export class AppModule { }
